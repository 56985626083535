import GameController from './GameController'
import Home from './Home'
import LogIn from './LogIn'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie'
import React from 'react';
import { instanceOf } from 'prop-types';
import axios from "axios";
import logo from './assets/logo.png'
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

axios.defaults.baseURL = "https://puzzle4good.herokuapp.com/";

function getNextPuzzle(size) {
  let min = 0;
  let max = 0;
  if (size === "8x8" || size === "10x10") {
      max = 11999;
  } else if (size === "14x14") {
      max = 5999;
  }
  return Math.floor(Math.random() * (max - min + 1) + min);
}

class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      name: cookies.get('username')
    };
    this.handleCookie = this.handleCookie.bind(this);
  }

  handleCookie(username) {
    const { cookies } = this.props;

    cookies.set('username', username, { path: '/' });
    this.setState({ username });
  }

  render() {
    const { cookies } = this.props;
    let button;
    if ((cookies.get('username') !== undefined) && (cookies.get('username') !== "undefined")) {
      button = <div className="username">{cookies.get('username')}</div>
    } else {
      button = <LogIn handleCookie={this.handleCookie}></LogIn>;
    }
    
    const next8x8 = getNextPuzzle("8x8");
    const next10x10 = getNextPuzzle("10x10");
    const next14x14 = getNextPuzzle("14x14");
    return (
      <BrowserRouter>
        <Navbar variant="dark" fixed="top" expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block logo-home"
            />{'    '}
            Star Battle Go          
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to={`/8x8/${next8x8}`}>8x8</Nav.Link>
              <Nav.Link as={Link} to={`/10x10/${next10x10}`}>10x10</Nav.Link>
              <Nav.Link as={Link} to={`/14x14/${next14x14}`}>14x14</Nav.Link>
            </Nav>
            <Nav>
              {button}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/:size/:id" element={<GameController username={cookies.get('username')}/>}/>
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default withCookies(App);
