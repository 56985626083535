import React from 'react';
import chunk from 'lodash/chunk';

import circle from './assets/circle.png'
import star from './assets/star.png'

class Square extends React.Component {
    render() {
        const classes = {'square': true, 
            'square-border-top': this.props.border_t, 
            'square-border-bottom': this.props.border_b, 
            'square-border-left': this.props.border_l, 
            'square-border-right': this.props.border_r,
            'is-solved': this.props.isSolved,
        };
        const classnames = classNames(classes);
        let img = null;
        if (this.props.value === 0) {
            img = <img src={circle} className="circle" alt="." draggable="false"></img>
        } else if (this.props.value === 1) {
            img = <img src={star} className="star" alt="X" draggable="false"></img>
        }
        return (
            <button 
              className={classnames}
              onMouseDown={() => this.props.onMouseDown()}
              onMouseEnter={() => this.props.onMouseEnter()}
              onMouseUp={() => this.props.onMouseUp()}
            >
                {img}
            </button>
        );
    }
}
  
class Board extends React.Component {
    renderSquare(i) {
        // Check if we need to add top/down/left/right borders for the current square
        const section = this.props.sections[i];
        const row = Math.floor(i / this.props.size);
        const col = i % this.props.size;
        let border_t = false;
        let border_b = false;
        let border_l = false;
        let border_r = false;
        // Top check
        if (row === 0 || this.props.sections[(row - 1 ) * this.props.size + col] !== section) {
            border_t = true;
        }
        // Bottom check
        if (row === (this.props.height - 1) || this.props.sections[(row + 1) * this.props.size + col] !== section) {
            border_b = true;
        }
        // Left check
        if (col === 0 || this.props.sections[i - 1] !== section) {
            border_l = true;
        }
        // Right check
        if (col === (this.props.size - 1) || this.props.sections[i + 1] !== section) {
            border_r = true;
        }
        return (
            <Square 
                key={i}
                value={this.props.squares[i]}
                onMouseDown={() => this.props.handleMouseDown(i)}
                onMouseEnter={() => this.props.handleMouseEnter(i)}
                onMouseUp={() => this.props.handleMouseUp()}
                border_t={border_t}
                border_b={border_b}
                border_l={border_l}
                border_r={border_r}
                isSolved={this.props.isSolved}
            />
        );
    }

    render() {
        // TODO if all squares are not null, check answers
        return (
            <div className="board">
                {chunk([...Array(this.props.size * this.props.size).keys()], this.props.size).map((row, row_num) => {
                    return (
                        <div className="board-row" key={row_num}>
                            {row.map(i => this.renderSquare(i))}
                        </div>
                    )
                })}
            </div>
        );
    }
}

function classNames(classes) {
    return Object.entries(classes)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
}

export default Board;