import React from 'react';
import Timer from './Timer';
import Board from './Board';
// import Modes from './Modes';
import { Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";

class Game extends React.Component {
    constructor(props) {
        super(props);

        // calculate next puzzle id
        let min = 0;
        let max = 0;
        if (this.props.size === "8x8" || this.props.size === "10x10") {
            max = 11999;
        } else if (this.props.size === "14x14") {
            max = 5999;
        }
        const nextPuzzleId = Math.floor(Math.random() * (max - min + 1) + min);

        this.state = {
            data: [],
            size_str: this.props.size,
            size: null,
            id: this.props.id,
            squares: [],
            sections: this.props.id,
            solved: this.props.solved,
            isSolved: false,
            isComplete: false,
            nextPuzzleId: nextPuzzleId,
            isDragging: false,
            currentDragged: [],
            clickHistory: [], // to save to database
            startTimeMs: null,
            endTimeMs: null,
            username: this.props.username,
            mode: 0, // 0 = default, 1 = sandbox mode, 2 = beast mode
            undone_moves: [],
        }
    }

    componentDidMount() {
        axios.get(`/api/game/boards/${this.state.size_str}/${this.state.id}`)
        .then(res => {
            const data = res.data.data;
            
            this.setState({data: data, 
                size: parseInt(data[3]), 
                sections: data[1], 
                solved: data[4], 
                squares: Array(Math.pow(parseInt(data[3]), 2)).fill(null),
                startTimeMs: Date.now(),
            });
        })
        .catch(err => console.log(err.response.data));
    }

    // Handles user click/click-by-drag
    handleMouseDown(i) {
        let currentTime = Date.now();
        let currentTimeElapsed = currentTime - this.state.startTimeMs;
        if (!this.state.isSolved) {
            this.setState({ isDragging: true })
            const squares = this.state.squares.slice();
            if (squares[i] === null) {
                squares[i] = 0;
            } else if (squares[i] === 0) {
                squares[i] = 1;
            } else {
                squares[i] = null;
            }
            this.setState({squares: squares}, () => {
                let currentDragged = this.state.currentDragged.slice();
                currentDragged.push(i);
                this.setState({currentDragged: currentDragged});
                if (this.isComplete()) {
                    const isSolved = this.checkSolved();
                    if (isSolved) {
                        this.setState({isSolved: isSolved, endTimeMs: currentTime}, () => {
                            this.sendGameDataToServer();
                        });
                    }
                }
            });
            // Update clickHistory in state to store user's moves
            const clickHistory = this.state.clickHistory.slice();
            clickHistory.push({ "index": i, "click_type": squares[i], "time_elapsed": currentTimeElapsed });
            this.setState({ clickHistory: clickHistory });
        }
    }

    handleMouseEnter(i) {
        if (this.state.isDragging) {
            if (!this.state.currentDragged.includes(i)) {
                this.handleMouseDown(i);
            }
        }
    }

    handleMouseUp() {
        this.setState({isDragging: false});
        this.setState({currentDragged: []});
    }

    checkSolved() {
        for (let i=0; i < this.state.squares.length; i++) {
            if (this.state.solved[i] === "1" && this.state.squares[i] !== 1) {
                return false;
            }
        }
        return true;
    }

    isComplete() {
        const total = this.state.squares.reduce(function (s, v) { return s + (v || 0); }, 0);
        let totalNeeded = 0;
        if (this.state.size === 8) {
            totalNeeded = 8;
        } else if (this.state.size === 10) {
            totalNeeded = 10 * 2;
        } else if (this.state.size === 14) {
            totalNeeded = 14 * 3;
        }
        this.setState({isComplete: (total >= totalNeeded)});
        return total >= totalNeeded;
    }

    sendGameDataToServer() {
        const gameHistoryObj = {};
        gameHistoryObj["username"] = this.state.username;
        gameHistoryObj["click_history"] = this.state.clickHistory;
        gameHistoryObj["game_size"] = this.state.size_str;
        gameHistoryObj["game_id"] = this.state.id;
        gameHistoryObj["time_started_ms"] = this.state.startTimeMs;
        gameHistoryObj["time_ended_ms"] = (this.state.endTimeMs ? this.state.endTimeMs : (this.state.startTimeMs? Date.now() : null));
        const currDatetime = new Date();
        gameHistoryObj["time_sent_str"] = currDatetime.toString();
        gameHistoryObj["time_taken_ms"] = gameHistoryObj["time_ended_ms"] - this.state.startTimeMs;
        gameHistoryObj["final_board_state"] = this.state.squares;
        gameHistoryObj["is_solved"] = this.state.isSolved;
        axios.post('/api/game/gamehistory', gameHistoryObj)
        .then(res => {
            console.log("Sent game history to server");
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }

    // componentWillUnmount() {
    //     console.log("Game component unmounting");
    //     this.sendGameDataToServer();
    // }

    render() {
        return (
            <div className="game">
                {/* <Modes></Modes> */}
                <Timer isSolved={this.state.isSolved}/>
                <div className="game-board" onMouseLeave={() => {this.handleMouseUp()}}>
                    <Board 
                        size={this.state.size} 
                        sections={this.state.sections} 
                        solved={this.state.solved} 
                        squares={this.state.squares}
                        isSolved={this.state.isSolved}
                        handleMouseDown={this.handleMouseDown.bind(this)}
                        handleMouseEnter={this.handleMouseEnter.bind(this)}
                        handleMouseUp={this.handleMouseUp.bind(this)}
                    />
                </div>
                <div className="game-info">
                    {this.state.isComplete
                        ? (this.state.isSolved ? <div>You did it!</div> : <div>Something's wrong...</div>)
                        : <div>Keep going!</div>
                    }
                </div>
                <Button variant="custom"><Link className="next-puzzle" to={`/${this.state.size_str}/${this.state.nextPuzzleId}`}>Next Puzzle</Link></Button>
            </div>
        );
    }
}

export default Game;