import React, { useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
// import axios from "axios";

function LogIn(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [username, setUsername] = useState();
    
    function handleSubmit(e) {
        e.preventDefault();
        // axios.post(`/api/account/login`, username)
        // .then(res => {
            
        // })
        // .catch(err => {
        //     console.log(err.response.data);
        // });
        props.handleCookie(username);
        setUsername();
    }
  
    return (
        <div>
            <Button variant="primary" className="btn-custom" onClick={handleShow}>
                Log In
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Log in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formBasicUsername">
                            <Form.Label column sm="3">Username</Form.Label>
                            <Col sm="9">
                                <Form.Control name="username" onChange={(e) => setUsername(e.target.value)} value={username} type="text" placeholder="Enter username" />
                            </Col>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" className="btn-custom" onClick={handleSubmit}>
                                Log In
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default LogIn;