import React from 'react'
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string'


import Game from './Game'

function GameController(props) {
    let params = useParams();
    let location = useLocation();

    let value = queryString.parse(location);
    let mode = value.mode;

    return <div><Game key={params.id} size={params.size} id={params.id} username={props.username} mode={mode}/></div>
}

export default GameController;